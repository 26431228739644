import { useTransaction } from 'context/transaction'
import { useVisitorTransaction } from 'context/visitorTransaction'
import { constants } from 'config'
import useTransactionType from '../transactionType'

const {
  LOCAL_STORAGE_KEYS: { TRANSACTION, VISITOR_TRANSACTION }
} = constants

function useTransactionContext() {
  const transaction = useTransaction()
  const visitorTransaction = useVisitorTransaction()
  const transactionType = useTransactionType()

  switch (transactionType) {
    case TRANSACTION:
      return transaction

    case VISITOR_TRANSACTION:
      return visitorTransaction

    default:
      return null
  }
}

export default useTransactionContext
