import React from 'react'
import { Box, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material'
import {
  Receipt as ReceiptIcon,
  PersonAdd as PersonAddIcon
} from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import { Link } from 'components'

const PATHS_WITH_FAB_BUTTON = ['/transactions', '/visitors']
const ACTIONS = [
  { icon: <PersonAddIcon />, name: 'Visitor', to: '/visitors/create/door' },
  {
    icon: <ReceiptIcon />,
    name: 'Transaction',
    to: '/transactions/create/door'
  }
]

function FabButton() {
  const { pathname } = useLocation()

  return PATHS_WITH_FAB_BUTTON?.includes(pathname) ? (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      position='fixed'
      bottom={40}
      right={15}
      flexGrow={1}
      sx={{ transform: 'translateZ(0px)' }}
    >
      <SpeedDial
        ariaLabel='SpeedDial basic example'
        sx={{ position: 'fixed', bottom: 40, right: 15 }}
        icon={<SpeedDialIcon />}
      >
        {ACTIONS.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            component={Link}
            to={action.to}
          />
        ))}
      </SpeedDial>
    </Box>
  ) : null
}

export default FabButton
