import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'
import PropTypes from 'prop-types'

import { constants } from 'config'
import { templateUtils } from 'utils'

const {
  LOCAL_STORAGE_KEYS: { VISITOR_TRANSACTION, VISITOR_TRANSACTION_TEMPLATE }
} = constants

const VisitorTransactionContext = createContext()

function VisitorTransactionProvider(props) {
  const [data, setData] = useState(null)
  const [template, setTemplate] = useState(null)

  useEffect(() => {
    if (data) {
      window.localStorage.setItem(VISITOR_TRANSACTION, JSON.stringify(data))
    }
  }, [data])

  useEffect(() => {
    if (template) {
      window.localStorage.setItem(
        VISITOR_TRANSACTION_TEMPLATE,
        JSON.stringify(template)
      )
    }
  }, [template])

  useEffect(() => {
    const persistTransactionData = window.localStorage.getItem(
      VISITOR_TRANSACTION
    )
    const persistTransactionTemplate = window.localStorage.getItem(
      VISITOR_TRANSACTION_TEMPLATE
    )

    if (persistTransactionData && persistTransactionTemplate) {
      setData(JSON.parse(persistTransactionData))
      setTemplate(JSON.parse(persistTransactionTemplate))
    }
  }, [])

  const setTransactionData = useCallback(
    (newData, overrideCurrentData = true) => {
      setData(currentData =>
        overrideCurrentData
          ? { ...currentData, ...newData }
          : { ...newData, ...currentData }
      )
    },
    []
  )

  const setTransactionTemplate = useCallback(template => {
    setTemplate(templateUtils.sortByPosition(template))
  }, [])

  const cleanTransactionData = useCallback(() => {
    window.localStorage.removeItem(VISITOR_TRANSACTION)
    window.localStorage.removeItem(VISITOR_TRANSACTION_TEMPLATE)
    setData(null)
    setTemplate(null)
  }, [])

  return (
    <VisitorTransactionContext.Provider
      value={{
        transactionData: data,
        setTransactionData,
        cleanTransactionData,
        setTransactionTemplate,
        transactionTemplate: template
      }}
      {...props}
    />
  )
}

function useVisitorTransaction() {
  const context = useContext(VisitorTransactionContext)

  if (context === undefined) {
    throw new Error(
      `useVisitorTransaction must be used within a VisitorTransactionProvider`
    )
  }

  return context
}

VisitorTransactionProvider.propTypes = {
  props: PropTypes.object
}

export { VisitorTransactionProvider, useVisitorTransaction }
