import { constants } from 'config'
import { templateUtils } from 'utils'

const {
  VISITOR_DEFAULT_FIELDS: { FIRST_NAME, LAST_NAME, COMMENTS },
  VISITOR_EXTRA_FIELDS,
  VISITOR_REQUIRED_FIELDS,
  FIELD_TYPES: { BOOLEAN }
} = constants

const format = (transaction, template) => {
  const trx = {}

  if (!template || !transaction) {
    return trx
  }

  Object.values(VISITOR_REQUIRED_FIELDS).map(name => {
    const value = transaction?.[name]

    trx[name] = value
  })

  Object.keys(template).map(key => {
    const step = template?.[key]
    const { fields } = step
    const sortedFields = templateUtils.sortByPosition(fields)
    const fieldKeys = Object.keys(sortedFields)

    fieldKeys.forEach(key => {
      const { isDefault, name, type } = sortedFields[key]
      const value = transaction[name] || null

      if (isDefault) {
        switch (key) {
          case FIRST_NAME:
          case LAST_NAME:
          case COMMENTS:
            trx[name] = value
            break

          default:
            trx[name] = value
            break
        }
      } else {
        if (!trx.custom_fields) {
          trx.custom_fields = {}
        }

        switch (type) {
          case BOOLEAN:
            trx.custom_fields[name] = value === true || value === 'true'
            break

          default:
            trx.custom_fields[name] = value
            break
        }
      }
    })
  })

  Object.values(VISITOR_EXTRA_FIELDS).map(name => {
    const value = transaction[name] || null

    trx[name] = value
  })

  return {
    variables: {
      visitor: trx
    }
  }
}

const formatUpdateValues = transaction => {
  const transactionTemplate = transaction?.transaction_template
  const template = transactionTemplate?.template
  const trx = {
    transaction_id: transaction?.id,
    uuid: transaction?.uuid
  }

  if (!template || !transaction) {
    return trx
  }

  Object.values(VISITOR_REQUIRED_FIELDS).forEach(name => {
    const value = transaction[name]

    trx[name] = value
  })

  Object.keys(template).forEach(key => {
    const step = template[key]
    const { fields } = step
    const sortedFields = templateUtils.sortByPosition(fields)
    const fieldKeys = Object.keys(sortedFields)

    fieldKeys.forEach(key => {
      const { isDefault, name, type } = sortedFields[key]
      const value = transaction[name] || null

      if (isDefault) {
        switch (key) {
          case FIRST_NAME:
          case LAST_NAME:
          case COMMENTS:
            trx[name] = value
            break

          default:
            trx[name] = value
            break
        }
      } else {
        if (!trx.custom_fields) {
          trx.custom_fields = {}
        }

        switch (type) {
          case BOOLEAN:
            trx.custom_fields[name] = value === true || value === 'true'
            break

          default:
            trx.custom_fields[name] = value
            break
        }
      }
    })
  })

  return {
    variables: {
      visitor: trx
    }
  }
}

export default {
  format,
  formatUpdateValues
}
