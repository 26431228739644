import { useLocation } from 'react-router-dom'

import { constants } from 'config'

const {
  LOCAL_STORAGE_KEYS: { TRANSACTION, VISITOR_TRANSACTION }
} = constants

const TRANSACTION_TYPES = {
  [TRANSACTION]: {
    path: 'transactions',
    type: TRANSACTION
  },
  [VISITOR_TRANSACTION]: {
    path: 'visitors',
    type: VISITOR_TRANSACTION
  }
}

function useTransactionType(type = null) {
  const { pathname } = useLocation()

  if (type) {
    return type
  }

  const transactionTypeKey = Object.keys(TRANSACTION_TYPES)?.find(type => {
    const transactionTypeValues = TRANSACTION_TYPES?.[type]
    return pathname?.includes(transactionTypeValues?.path)
  })

  const transactionType = TRANSACTION_TYPES?.[transactionTypeKey]

  return transactionType?.type
}

export default useTransactionType
