import React from 'react'
import { Tabs, Tab } from '@mui/material'
import {
  Receipt as ReceiptIcon,
  People as PeopleIcon
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'

import { Link } from 'components'

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: '100%',
  zIndex: 999,
  backgroundColor: theme.palette.background.paper
}))

const StyledTab = styled(Tab)(() => ({
  flex: 1,
  maxWidth: '100%'
}))

const TABS = {
  '/transactions': {
    value: 0,
    path: '/transactions',
    icon: <ReceiptIcon />,
    label: 'Transactions'
  },
  '/visitors': {
    value: 1,
    path: '/visitors',
    icon: <PeopleIcon />,
    label: 'Visitors'
  }
}

function BottomTabs() {
  const { pathname } = useLocation()
  const tabKeys = Object.keys(TABS)
  const currentTab = TABS?.[pathname]

  return (
    <StyledTabs value={currentTab?.value}>
      {tabKeys?.map(tabKey => {
        const tabValues = TABS?.[tabKey]
        return (
          <StyledTab
            key={tabKey}
            icon={tabValues?.icon}
            label={tabValues?.label}
            component={Link}
            to={tabValues?.path}
          />
        )
      })}
    </StyledTabs>
  )
}

export default BottomTabs
