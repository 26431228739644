import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  FormHelperText
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'

import { constants } from 'config'
import {
  BaseTextField,
  FileUpload,
  FileUploadTextField,
  CameraSelect,
  FileUploadPreview
} from 'components'
import { dateUtils } from 'utils'
import { useFileUpload } from 'hooks'
import useTransactionContext from 'hooks/transactionContext'
import { iso_types, bool_options, container_conditions } from './data.json'

const {
  ENUM_DATA: { PHOTO_TYPES, SEAL_TYPES, DAMAGE_TYPES },
  PHOTO_KEYS: { PHOTO: PHOTO_KEY },
  FIELD_TYPES: { NUMBER, STRING, BOOLEAN, SELECT, PHOTO, MULTI_SELECT },
  DEFAULT_FIELDS: {
    CONTAINER_ISO_TYPE,
    MGW_KILOGRAMS,
    MAX_PAYLOAD_KILOGRAMS,
    TARE_KILOGRAMS,
    CARGO_WEIGHT_KILOGRAMS,
    VOLUME_CBM,
    CONTAINER_YEAR,
    CONTAINER_EMPTY_PHOTO,
    SEAL_PHOTOS,
    LINER_ID,
    SHIPPER_ID,
    CONSIGNEE_ID,
    BOOKING_NUMBER,
    DRIVER_ID_PHOTO,
    DRIVER_NAME,
    DRIVER_ID,
    DRIVER_PHOTO,
    PLATE_PHOTO,
    PLATE_ID,
    IS_DAMAGE,
    DAMAGE_PHOTOS,
    CONTAINER_CONDITION,
    STACK,
    COMMENTS,
    CONTAINER_CONDITION_PHOTOS,
    PTI_PASS,
    CLEAN,
    SET_POINT_CELSIUS,
    RETURN_TEMPERATURE,
    SUPPLY_TEMPERATURE,
    HUMIDITY_SET_POINT,
    HUMIDITY_ACTUAL,
    VENTILATION
  },
  VISITOR_DEFAULT_FIELDS: { FIRST_NAME, LAST_NAME, COMMENTS: VISITOR_COMMENTS }
} = constants

const getIsLoading = (name, values) => {
  switch (name) {
    case DRIVER_NAME:
    case DRIVER_ID:
      return !!values?.[DRIVER_ID_PHOTO]?.loading

    case PLATE_ID:
      return !!values?.[PLATE_PHOTO]?.loading

    default:
      return false
  }
}

const getOrganizationValues = name => {
  switch (name) {
    case LINER_ID:
      return {
        label: 'liner',
        organizationType: 'liners',
        field: 'liner'
      }

    case SHIPPER_ID:
      return {
        label: 'shipper',
        organizationType: 'shippers',
        field: 'shipper'
      }

    case CONSIGNEE_ID:
      return {
        label: 'consignee',
        organizationType: 'consignees',
        field: 'consignee'
      }

    default:
      return null
  }
}

function FieldsFactory({ isDefault = true, name, type, formikProps, options }) {
  const { t } = useTranslation()
  const { upload, getFromIpCamera } = useFileUpload()
  const { transactionData } = useTransactionContext()
  const { touched, errors, values, setFieldValue } = formikProps
  const cameras = transactionData?.cameras
  const [cameraSelectOpen, setCameraSelectOpen] = useState(false)

  const handleOpenCameraSelect = useCallback(
    () => setCameraSelectOpen(true),
    []
  )

  const handleCloseCameraSelect = useCallback(
    () => setCameraSelectOpen(false),
    []
  )

  if (isDefault) {
    switch (name) {
      case CONTAINER_ISO_TYPE:
        return (
          <Field
            name={name}
            as={BaseTextField}
            select
            SelectProps={{
              native: true
            }}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
          >
            <option value='' />
            {iso_types.map(iso => {
              return (
                <option key={iso.code} value={iso.code}>
                  {`${iso.code} - ${t(iso.description)}`}
                </option>
              )
            })}
          </Field>
        )

      case MGW_KILOGRAMS:
      case MAX_PAYLOAD_KILOGRAMS:
      case TARE_KILOGRAMS:
      case CARGO_WEIGHT_KILOGRAMS:
      case VOLUME_CBM:
      case SET_POINT_CELSIUS:
      case RETURN_TEMPERATURE:
      case SUPPLY_TEMPERATURE:
      case HUMIDITY_SET_POINT:
      case HUMIDITY_ACTUAL:
      case VENTILATION:
        return (
          <Field
            name={name}
            type='number'
            as={BaseTextField}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
          />
        )

      case BOOKING_NUMBER:
      case DRIVER_NAME:
      case DRIVER_ID:
      case PLATE_ID:
      case STACK:
      case COMMENTS:
      case VISITOR_COMMENTS:
      case FIRST_NAME:
      case LAST_NAME:
        return (
          <Field
            name={name}
            as={BaseTextField}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            loading={getIsLoading(name, values)}
          />
        )

      case CONTAINER_YEAR:
        return (
          <Field
            name={name}
            type='number'
            as={BaseTextField}
            select
            SelectProps={{
              native: true
            }}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
          >
            <option value='' />
            {dateUtils.getArrayOfLastYears().map(year => {
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            })}
          </Field>
        )

      case CONTAINER_EMPTY_PHOTO:
        return (
          <Field name={name}>
            {({ field: { value } }) => {
              return (
                <FileUpload
                  name={name}
                  spacing
                  label={t(name)}
                  loading={!!value?.loading}
                  onChange={async ({
                    target: {
                      validity,
                      files: [file]
                    }
                  }) => {
                    try {
                      if (validity.valid && file) {
                        setFieldValue(name, {
                          ...value,
                          loading: true
                        })

                        const response = await upload({
                          url: 'upload/photo',
                          file,
                          type: PHOTO_TYPES.CONTAINER_EMPTY,
                          throwError: true,
                          high: false
                        })

                        setFieldValue(name, {
                          ...response
                        })
                      }
                    } catch (error) {
                      setFieldValue(name, {
                        ...value,
                        loading: false
                      })
                    }
                  }}
                  value={value?.hash}
                  error={touched[name] && t(errors[name]?.hash)}
                />
              )
            }}
          </Field>
        )

      case DRIVER_ID_PHOTO:
        return (
          <Field name={name}>
            {({ field: { value } }) => {
              return (
                <FileUpload
                  name={name}
                  spacing
                  label={t(name)}
                  loading={!!value?.loading}
                  onChange={async ({
                    target: {
                      validity,
                      files: [file]
                    }
                  }) => {
                    try {
                      if (validity.valid && file) {
                        setFieldValue(name, {
                          ...value,
                          loading: true
                        })

                        const { driver_id, driver_name, photo } = await upload({
                          url: 'upload/driver-id-photo',
                          file,
                          key: PHOTO_KEY,
                          throwError: true
                        })

                        setFieldValue(name, { ...photo })
                        setFieldValue(DRIVER_ID, driver_id)
                        setFieldValue(DRIVER_NAME, driver_name)
                      }
                    } catch (error) {
                      setFieldValue(name, {
                        ...value,
                        loading: false
                      })
                    }
                  }}
                  value={value?.hash}
                  error={touched[name] && t(errors[name]?.hash)}
                />
              )
            }}
          </Field>
        )

      case DRIVER_PHOTO:
        return (
          <Field name={name}>
            {({ field: { value } }) => {
              return (
                <FileUpload
                  name={name}
                  spacing
                  label={t(name)}
                  loading={!!value?.loading}
                  onChange={async ({
                    target: {
                      validity,
                      files: [file]
                    }
                  }) => {
                    try {
                      if (validity.valid && file) {
                        setFieldValue(name, {
                          ...value,
                          loading: true
                        })

                        const response = await upload({
                          url: 'upload/photo',
                          file,
                          type: PHOTO_TYPES.DRIVER,
                          throwError: true,
                          high: false
                        })

                        setFieldValue(name, { ...response })
                      }
                    } catch (error) {
                      setFieldValue(name, {
                        ...value,
                        loading: false
                      })
                    }
                  }}
                  value={value?.hash}
                  error={touched[name] && t(errors[name]?.hash)}
                />
              )
            }}
          </Field>
        )

      case PLATE_PHOTO:
        return (
          <Field name={name}>
            {({ field: { value } }) => {
              return (
                <>
                  <FileUpload
                    name={name}
                    spacing
                    label={t(name)}
                    loading={!!value?.loading}
                    onChange={async ({
                      target: {
                        validity,
                        files: [file]
                      }
                    }) => {
                      try {
                        if (validity.valid && file) {
                          setFieldValue(name, {
                            ...value,
                            loading: true
                          })

                          const { plate_id, photo } = await upload({
                            url: 'upload/plate-photo',
                            file,
                            key: PHOTO_KEY,
                            throwError: true
                          })

                          setFieldValue(name, { ...photo })
                          setFieldValue(PLATE_ID, plate_id)
                        }
                      } catch (error) {
                        setFieldValue(name, {
                          ...value,
                          loading: false
                        })
                      }
                    }}
                    value={value?.hash}
                    allowIpCameras={!!cameras}
                    onIpCameraPress={handleOpenCameraSelect}
                    error={touched[name] && t(errors[name]?.hash)}
                  />

                  {cameras && (
                    <CameraSelect
                      cameras={cameras}
                      onCameraSelect={async ({ id }) => {
                        try {
                          setCameraSelectOpen(false)
                          setFieldValue(name, {
                            ...value,
                            loading: true
                          })

                          const { plate_id, photo } = await getFromIpCamera(
                            `remote-camera/plate-photo?id=${id}`,
                            true
                          )

                          setFieldValue(name, { ...photo })
                          setFieldValue(PLATE_ID, plate_id)
                        } catch (error) {
                          setFieldValue(name, {
                            ...value,
                            loading: false
                          })
                        }
                      }}
                      open={cameraSelectOpen}
                      handleClose={handleCloseCameraSelect}
                    />
                  )}
                </>
              )
            }}
          </Field>
        )

      case SEAL_PHOTOS:
        return (
          <FieldArray name={name}>
            {({ push, remove }) => {
              return (
                <>
                  <Typography>{t(name)}</Typography>

                  {values?.[name]?.map((seal, index) => {
                    return (
                      <Field name={`${name}.${index}`} key={`${name}.${index}`}>
                        {({ field }) => {
                          const { value } = field

                          return (
                            <>
                              <Field
                                name={`${name}.${index}.seal_type`}
                                as={BaseTextField}
                                select
                                SelectProps={{
                                  native: true
                                }}
                                label={t('seal_type')}
                                error={
                                  !!(
                                    getIn(
                                      touched,
                                      `${name}.${index}.seal_type`
                                    ) &&
                                    getIn(errors, `${name}.${index}.seal_type`)
                                  )
                                }
                                helperText={
                                  getIn(
                                    touched,
                                    `${name}.${index}.seal_type`
                                  ) &&
                                  t(getIn(errors, `${name}.${index}.seal_type`))
                                }
                              >
                                <option value='' />
                                {Object.keys(SEAL_TYPES).map(key => {
                                  const value = SEAL_TYPES[key]
                                  return (
                                    <option key={value} value={value}>
                                      {t(value)}
                                    </option>
                                  )
                                })}
                              </Field>

                              <FileUploadTextField
                                spacing
                                allowDelete
                                onDelete={() => remove(index)}
                                error={
                                  !!(
                                    getIn(touched, `${name}.${index}.value`) &&
                                    getIn(errors, `${name}.${index}.value`)
                                  )
                                }
                                helperText={
                                  getIn(touched, `${name}.${index}.value`) &&
                                  t(getIn(errors, `${name}.${index}.value`))
                                }
                                fileError={
                                  getIn(touched, `${name}.${index}`) &&
                                  t(getIn(errors, `${name}.${index}.hash`))
                                }
                                onFileChange={async ({
                                  target: {
                                    validity,
                                    files: [file]
                                  }
                                }) => {
                                  try {
                                    if (validity.valid && file) {
                                      setFieldValue(`${name}.${index}`, {
                                        ...value,
                                        loading: true
                                      })

                                      const { photo, seal_id } = await upload({
                                        url: 'upload/seal-photo',
                                        file,
                                        key: PHOTO,
                                        throwError: true
                                      })

                                      setFieldValue(`${name}.${index}`, {
                                        ...value,
                                        ...photo,
                                        value: seal_id
                                      })
                                    }
                                  } catch (error) {
                                    setFieldValue(`${name}.${index}`, {
                                      ...value,
                                      loading: false
                                    })
                                  }
                                }}
                                {...field}
                                label={t('seal_id')}
                                file={value?.hash}
                                loading={!!value?.loading}
                                value={value?.value}
                                onChange={({ target: { value: seal_id } }) => {
                                  setFieldValue(`${name}.${index}`, {
                                    ...value,
                                    value: seal_id
                                  })
                                }}
                              />
                            </>
                          )
                        }}
                      </Field>
                    )
                  })}

                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    mb={1}
                  >
                    <Tooltip title={t('addSeal')}>
                      <span>
                        <IconButton
                          onClick={() => {
                            push({
                              hash: '',
                              value: '',
                              type: '',
                              seal_type: ''
                            })
                          }}
                          disabled={!!(values?.[name]?.length === 4)}
                          size='large'
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>

                  {touched?.[name] && typeof errors?.[name] === 'string' ? (
                    <FormHelperText
                      error={true}
                      variant='outlined'
                      margin={undefined}
                    >
                      {t(errors[name])}
                    </FormHelperText>
                  ) : null}
                </>
              )
            }}
          </FieldArray>
        )

      case LINER_ID:
      case SHIPPER_ID:
      case CONSIGNEE_ID: {
        const organizationValues = getOrganizationValues(name)

        if (!organizationValues) {
          return null
        }

        const { label, organizationType, field } = organizationValues

        const organizations = transactionData?.[organizationType] || []

        return (
          <Field name={name}>
            {({ field: { name } }) => {
              return (
                <Autocomplete
                  id={name}
                  options={organizations}
                  getOptionLabel={option =>
                    option?.trade_name
                      ? `${option.name} - ${option.trade_name}`
                      : option.name
                  }
                  value={values?.[field]}
                  onChange={(_, value) => {
                    if (value) {
                      setFieldValue(field, value)
                      setFieldValue(name, value.id)
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loadingText={t('loading')}
                  noOptionsText={t('noOptions')}
                  renderInput={params => {
                    return (
                      <BaseTextField
                        {...params}
                        label={t(label)}
                        error={!!(touched[name] && errors[name])}
                        helperText={touched[name] && t(errors[name])}
                        InputProps={{
                          ...params.InputProps
                        }}
                      />
                    )
                  }}
                />
              )
            }}
          </Field>
        )
      }

      case IS_DAMAGE:
      case PTI_PASS:
      case CLEAN:
        return (
          <Field
            name={name}
            as={BaseTextField}
            select
            SelectProps={{
              native: true
            }}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
          >
            <option value='' />
            {bool_options.map(({ id, value, name }) => (
              <option key={id} value={value}>
                {t(name)}
              </option>
            ))}
          </Field>
        )

      case CONTAINER_CONDITION:
        return (
          <Field
            name={name}
            as={BaseTextField}
            select
            SelectProps={{
              native: true
            }}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
          >
            <option value='' />
            {container_conditions.map(({ id, value }) => (
              <option key={id} value={value}>
                {value}
              </option>
            ))}
          </Field>
        )

      case DAMAGE_PHOTOS:
        return values?.is_damage === true || values?.is_damage === 'true' ? (
          <FieldArray name={name}>
            {({ push, remove }) => {
              return (
                <>
                  <Typography>{t(name)}</Typography>

                  {values?.[name]?.map((damage, index) => {
                    return (
                      <Field name={`${name}.${index}`} key={`${name}.${index}`}>
                        {({ field }) => {
                          const { value } = field

                          return (
                            <>
                              <Field
                                name={`${name}.${index}.damage_type`}
                                as={BaseTextField}
                                select
                                SelectProps={{
                                  native: true
                                }}
                                label={t('damage_type')}
                                error={
                                  !!(
                                    getIn(
                                      touched,
                                      `${name}.${index}.damage_type`
                                    ) &&
                                    getIn(
                                      errors,
                                      `${name}.${index}.damage_type`
                                    )
                                  )
                                }
                                helperText={
                                  getIn(
                                    touched,
                                    `${name}.${index}.damage_type`
                                  ) &&
                                  t(
                                    getIn(
                                      errors,
                                      `${name}.${index}.damage_type`
                                    )
                                  )
                                }
                              >
                                <option value='' />
                                {Object.keys(DAMAGE_TYPES).map(key => {
                                  const value = DAMAGE_TYPES[key]
                                  return (
                                    <option key={value} value={value}>
                                      {t(value)}
                                    </option>
                                  )
                                })}
                              </Field>

                              <FileUploadPreview
                                allowDelete
                                onDelete={() => remove(index)}
                                name={field.name}
                                onFileChange={async ({
                                  target: {
                                    validity,
                                    files: [file]
                                  }
                                }) => {
                                  try {
                                    if (validity.valid && file) {
                                      setFieldValue(`${name}.${index}`, {
                                        ...value,
                                        loading: true
                                      })

                                      const response = await upload({
                                        url: 'upload/photo',
                                        file,
                                        type: PHOTO_TYPES.DAMAGE,
                                        high: false
                                      })

                                      setFieldValue(`${name}.${index}`, {
                                        ...value,
                                        ...response
                                      })
                                    }
                                  } catch (error) {
                                    setFieldValue(`${name}.${index}`, {
                                      ...value,
                                      loading: false
                                    })
                                  }
                                }}
                                file={value?.hash}
                                loading={!!value?.loading}
                                error={
                                  getIn(touched, `${name}.${index}`) &&
                                  t(getIn(errors, `${name}.${index}.hash`))
                                }
                              />
                            </>
                          )
                        }}
                      </Field>
                    )
                  })}

                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    mb={1}
                  >
                    <Tooltip title={t('addDamage')}>
                      <span>
                        <IconButton
                          onClick={() => {
                            push({
                              damage_type: '',
                              hash: '',
                              type: ''
                            })
                          }}
                          disabled={!!(values?.[name]?.length === 4)}
                          size='large'
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>

                  {touched?.[name] && typeof errors?.[name] === 'string' ? (
                    <FormHelperText
                      error={true}
                      variant='outlined'
                      margin={undefined}
                    >
                      {t(errors[name])}
                    </FormHelperText>
                  ) : null}
                </>
              )
            }}
          </FieldArray>
        ) : null

      case CONTAINER_CONDITION_PHOTOS:
        return (
          <FieldArray name={name}>
            {({ push, remove }) => {
              return (
                <>
                  <Typography>{t(name)}</Typography>
                  {values?.[name]?.map((condition, index) => {
                    return (
                      <Field name={`${name}.${index}`} key={`${name}.${index}`}>
                        {({ field }) => {
                          const { value } = field

                          return (
                            <>
                              {cameras && (
                                <CameraSelect
                                  cameras={cameras}
                                  onCameraSelect={async ({ id }) => {
                                    try {
                                      setFieldValue(`${name}.${index}`, {
                                        ...value,
                                        loading: true,
                                        cameraSelectOpen: false
                                      })

                                      const response = await getFromIpCamera(
                                        `remote-camera/photo?id=${id}&type=${PHOTO_TYPES.CONTAINER_CONDITION}`,
                                        true
                                      )

                                      setFieldValue(`${name}.${index}`, {
                                        ...response
                                      })
                                    } catch (error) {
                                      setFieldValue(`${name}.${index}`, {
                                        ...value,
                                        loading: false,
                                        cameraSelectOpen: false
                                      })
                                    }
                                  }}
                                  open={!!value?.cameraSelectOpen}
                                  handleClose={() =>
                                    setFieldValue(`${name}.${index}`, {
                                      ...value,
                                      cameraSelectOpen: false
                                    })
                                  }
                                />
                              )}

                              <FileUploadPreview
                                allowDelete
                                onDelete={() => remove(index)}
                                name={field.name}
                                onFileChange={async ({
                                  target: {
                                    validity,
                                    files: [file]
                                  }
                                }) => {
                                  try {
                                    if (validity.valid && file) {
                                      setFieldValue(`${name}.${index}`, {
                                        ...value,
                                        loading: true
                                      })

                                      const response = await upload({
                                        url: 'upload/photo',
                                        file,
                                        type: PHOTO_TYPES.CONTAINER_CONDITION,
                                        throwError: true,
                                        high: false
                                      })

                                      setFieldValue(`${name}.${index}`, {
                                        ...response
                                      })
                                    }
                                  } catch (error) {
                                    setFieldValue(`${name}.${index}`, {
                                      ...value,
                                      loading: false
                                    })
                                  }
                                }}
                                file={value?.hash}
                                loading={!!value?.loading}
                                allowIpCameras={!!cameras}
                                onIpCameraPress={() =>
                                  setFieldValue(`${name}.${index}`, {
                                    ...value,
                                    cameraSelectOpen: true
                                  })
                                }
                                error={
                                  getIn(touched, `${name}.${index}`) &&
                                  t(getIn(errors, `${name}.${index}.hash`))
                                }
                              />
                            </>
                          )
                        }}
                      </Field>
                    )
                  })}

                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    mb={1}
                  >
                    <Tooltip title={t('addContainerCondition')}>
                      <span>
                        <IconButton
                          onClick={() => {
                            push({
                              hash: '',
                              type: ''
                            })
                          }}
                          disabled={!!(values?.[name]?.length === 6)}
                          size='large'
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                  {touched?.[name] && typeof errors?.[name] === 'string' ? (
                    <FormHelperText
                      error={true}
                      variant='outlined'
                      margin={undefined}
                    >
                      {t(errors[name])}
                    </FormHelperText>
                  ) : null}
                </>
              )
            }}
          </FieldArray>
        )

      default:
        return <div>{name}</div>
    }
  }

  switch (type) {
    case NUMBER:
    case STRING:
      return (
        <Field
          name={name}
          type={type === NUMBER ? 'number' : 'string'}
          as={BaseTextField}
          label={name}
          error={!!(touched[name] && errors[name])}
          helperText={touched[name] && t(errors[name])}
        />
      )

    case BOOLEAN:
      return (
        <Field
          name={name}
          as={BaseTextField}
          select
          SelectProps={{
            native: true
          }}
          label={name}
          error={!!(touched[name] && errors[name])}
          helperText={touched[name] && t(errors[name])}
        >
          <option value='' />
          {bool_options.map(({ id, value, name }) => (
            <option key={id} value={value}>
              {t(name)}
            </option>
          ))}
        </Field>
      )

    case SELECT:
      return (
        <Field
          name={name}
          as={BaseTextField}
          select
          SelectProps={{
            native: true
          }}
          label={name}
          error={!!(touched[name] && errors[name])}
          helperText={touched[name] && t(errors[name])}
        >
          <option value='' />
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Field>
      )

    case MULTI_SELECT:
      return (
        <Field
          name={name}
          as={BaseTextField}
          select
          SelectProps={{
            native: true,
            multiple: true
          }}
          label={name}
          error={!!(touched[name] && errors[name])}
          helperText={touched[name] && t(errors[name])}
          value={values[name] || []}
        >
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Field>
      )

    case PHOTO:
      return (
        <Field name={name}>
          {({ field: { value } }) => {
            return (
              <FileUpload
                name={name}
                spacing
                label={name}
                loading={!!value?.loading}
                onChange={async ({
                  target: {
                    validity,
                    files: [file]
                  }
                }) => {
                  try {
                    if (validity.valid && file) {
                      setFieldValue(name, {
                        ...value,
                        loading: true
                      })

                      const response = await upload({
                        url: 'upload/photo',
                        file,
                        type: PHOTO_TYPES.CUSTOM,
                        throwError: true,
                        high: false
                      })

                      setFieldValue(name, {
                        ...response
                      })
                    }
                  } catch (error) {
                    setFieldValue(name, {
                      ...value,
                      loading: false
                    })
                  }
                }}
                value={value?.hash}
                error={touched[name] && t(errors[name]?.hash)}
              />
            )
          }}
        </Field>
      )

    default:
      return <div>default</div>
  }
}

FieldsFactory.propTypes = {
  isDefault: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.oneOf([NUMBER, STRING, BOOLEAN, SELECT, MULTI_SELECT, PHOTO]),
  options: PropTypes.arrayOf(PropTypes.string),
  formikProps: PropTypes.object
}

export default FieldsFactory
