import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import get from 'lodash.get'

import { constants } from 'config'
import { photoUtils, templateUtils } from 'utils'

const {
  DEFAULT_FIELDS: {
    CONTAINER_NUMBER,
    TRANSPORTATION_TYPE,
    CONTAINER_STATUS,
    CONTAINER_ISO_TYPE,
    MGW_KILOGRAMS,
    MAX_PAYLOAD_KILOGRAMS,
    TARE_KILOGRAMS,
    CARGO_WEIGHT_KILOGRAMS,
    VOLUME_CBM,
    CONTAINER_YEAR,
    LINER_ID,
    SHIPPER_ID,
    CONSIGNEE_ID,
    BOOKING_NUMBER,
    DRIVER_NAME,
    DRIVER_ID,
    PLATE_ID,
    IS_DAMAGE,
    DAMAGE_PHOTOS,
    CONTAINER_CONDITION,
    STACK,
    COMMENTS,
    PTI_PASS,
    CLEAN,
    SET_POINT_CELSIUS,
    RETURN_TEMPERATURE,
    SUPPLY_TEMPERATURE,
    HUMIDITY_SET_POINT,
    HUMIDITY_ACTUAL,
    VENTILATION
  },
  EXTRA_FIELDS,
  REQUIRED_FIELDS,
  REQUIRED_UPDATE_FIELDS,
  DOOR_FIELDS: {
    ID,
    UUID,
    CREATED_AT,
    UPDATED_AT,
    CREATED_BY,
    CLIENT_ID,
    TYPE,
    CONSECUTIVE_FOLIO_NUMBER
  },
  FIELD_TYPES: { BOOLEAN },
  DEFAULT_TIMEZONE,
  VISITOR_DEFAULT_FIELDS: { ID_NUMBER, FIRST_NAME, LAST_NAME }
} = constants
const { formatPhotosObject } = photoUtils

const gateInTransactionFormat = transaction => {
  const response = {
    type: transaction.type,
    liner_id: transaction.liner_id,
    shipper_id: transaction.shipper_id,
    booking_number: transaction.booking_number,
    consignee_id: transaction.consignee_id,
    is_damage: transaction.is_damage,
    container_condition: transaction.container_condition,
    container_iso_type: transaction.container_iso_type,
    mgw_kilograms: transaction.mgw_kilograms,
    max_payload_kilograms: transaction.max_payload_kilograms,
    tare_kilograms: transaction.tare_kilograms,
    volume_cbm: transaction.volume_cbm,
    container_year: transaction.container_year,
    release_name: transaction.release_name,
    release_party: transaction.release_party
  }

  return response
}

const format = (transaction, template) => {
  const trx = {}

  if (!template || !transaction) {
    return trx
  }

  const is_damage = !!(
    transaction?.is_damage === true || transaction?.is_damage === 'true'
  )

  Object.values(REQUIRED_FIELDS).map(name => {
    const value = transaction[name]

    trx[name] = name === CLIENT_ID ? parseInt(value) : value
  })

  Object.keys(template).map(key => {
    const step = template[key]
    const { fields } = step
    const sortedFields = templateUtils.sortByPosition(fields)
    const fieldKeys = Object.keys(sortedFields)

    fieldKeys.map(key => {
      const { isDefault, name, type } = sortedFields[key]
      const value = transaction[name] || null

      if (isDefault) {
        switch (key) {
          case LINER_ID:
          case SHIPPER_ID:
          case CONSIGNEE_ID:
          case CONTAINER_YEAR:
            trx[name] = value ? parseInt(value) : value
            break

          case IS_DAMAGE:
          case PTI_PASS:
          case CLEAN:
            trx[name] = value === true || value === 'true'
            break

          case DAMAGE_PHOTOS:
            trx[name] = is_damage ? value : null
            break

          default:
            trx[name] = value
            break
        }
      } else {
        if (!trx.custom_fields) {
          trx.custom_fields = {}
        }

        switch (type) {
          case BOOLEAN:
            trx.custom_fields[name] = value === true || value === 'true'
            break

          default:
            trx.custom_fields[name] = value
            break
        }
      }
    })
  })

  Object.values(EXTRA_FIELDS).map(name => {
    const value = transaction[name] || null

    trx[name] = value
  })

  return {
    variables: {
      transaction: trx
    }
  }
}

const findFieldValue = (field, transaction) => {
  switch (field) {
    case CONTAINER_NUMBER:
    case CONTAINER_ISO_TYPE:
    case MGW_KILOGRAMS:
    case MAX_PAYLOAD_KILOGRAMS:
    case TARE_KILOGRAMS:
    case CARGO_WEIGHT_KILOGRAMS:
    case VOLUME_CBM:
    case CONTAINER_YEAR:
      return {
        isPhoto: false,
        withT: false,
        value: transaction?.inventory_item?.[field]
      }

    case TYPE:
    case CONTAINER_STATUS:
    case TRANSPORTATION_TYPE:
      return {
        isPhoto: false,
        withT: true,
        value: transaction?.[field]
      }

    case IS_DAMAGE:
    case PTI_PASS:
    case CLEAN:
      return {
        isPhoto: false,
        withT: true,
        value: transaction?.[field]?.toString().toUpperCase()
      }

    case ID:
    case UUID:
    case BOOKING_NUMBER:
    case DRIVER_NAME:
    case DRIVER_ID:
    case PLATE_ID:
    case CONTAINER_CONDITION:
    case STACK:
    case COMMENTS:
    case SET_POINT_CELSIUS:
    case RETURN_TEMPERATURE:
    case SUPPLY_TEMPERATURE:
    case HUMIDITY_SET_POINT:
    case HUMIDITY_ACTUAL:
    case VENTILATION:
    case CONSECUTIVE_FOLIO_NUMBER:
    case ID_NUMBER:
    case FIRST_NAME:
    case LAST_NAME:
      return {
        isPhoto: false,
        withT: false,
        value: transaction?.[field]
      }

    case CREATED_AT:
    case UPDATED_AT:
      return {
        isPhoto: false,
        withT: false,
        value: moment(transaction?.[field])
          .tz(
            get(
              transaction,
              'transaction.location.timezone.name',
              DEFAULT_TIMEZONE
            )
          )
          .format('DD-MM-YYYY HH:mm z')
      }

    case CREATED_BY:
      return {
        isPhoto: false,
        withT: false,
        value: `${transaction?.user?.first_name} ${transaction?.user?.last_name}`
      }

    case CLIENT_ID:
    case LINER_ID:
    case SHIPPER_ID:
    case CONSIGNEE_ID:
      return {
        isPhoto: false,
        withT: false,
        value: transaction?.[field.replace('_id', '')]?.name
      }

    default: {
      const value = transaction?.custom_fields?.[field]
      const isPhoto = typeof value === 'object' && value?.hash
      return {
        isPhoto,
        withT: false,
        value: isPhoto ? value?.hash : value
      }
    }
  }
}

const formatFormValues = object => {
  const objectCopy = { ...object }
  const photos = formatPhotosObject(object.photos)
  const {
    container_number,
    container_iso_type,
    container_year,
    max_payload_kilograms,
    mgw_kilograms,
    tare_kilograms,
    volume_cbm
  } = object.inventory_item
  const customFields = object.custom_fields
  delete objectCopy.inventory_item
  delete objectCopy.custom_fields

  const response = {
    ...objectCopy,
    ...photos,
    cargo_weight_kilograms: objectCopy.cargo_weight_kilograms || '',
    comments: objectCopy.comments || '',
    container_number,
    container_iso_type,
    container_year,
    max_payload_kilograms,
    mgw_kilograms,
    tare_kilograms,
    volume_cbm,
    transaction_id: objectCopy.id,
    ...customFields
  }

  return response
}

const formatUpdateValues = transaction => {
  const transactionTemplate = transaction?.transaction_template
  const template = transactionTemplate?.template
  const trx = {
    transaction_id: transaction?.id,
    uuid: transaction?.uuid
  }

  if (!template || !transaction) {
    return trx
  }

  const is_damage = !!(
    transaction?.is_damage === true || transaction?.is_damage === 'true'
  )

  Object.values(REQUIRED_UPDATE_FIELDS).forEach(name => {
    const value = transaction[name]

    trx[name] =
      name === REQUIRED_UPDATE_FIELDS.CLIENT_ID ? parseInt(value) : value
  })

  Object.keys(template).forEach(key => {
    const step = template[key]
    const { fields } = step
    const sortedFields = templateUtils.sortByPosition(fields)
    const fieldKeys = Object.keys(sortedFields)

    fieldKeys.forEach(key => {
      const { isDefault, name, type } = sortedFields[key]
      const value = transaction[name] || null

      if (isDefault) {
        switch (key) {
          case LINER_ID:
          case SHIPPER_ID:
          case CONSIGNEE_ID:
          case CONTAINER_YEAR:
            trx[name] = value ? parseInt(value) : value
            break

          case IS_DAMAGE:
          case PTI_PASS:
          case CLEAN:
            trx[name] = value === true || value === 'true'
            break

          case DAMAGE_PHOTOS:
            trx[name] = is_damage ? value : null
            break

          default:
            trx[name] = value
            break
        }
      } else {
        if (!trx.custom_fields) {
          trx.custom_fields = {}
        }

        switch (type) {
          case BOOLEAN:
            trx.custom_fields[name] = value === true || value === 'true'
            break

          default:
            trx.custom_fields[name] = value
            break
        }
      }
    })
  })

  return {
    variables: {
      transaction: trx
    }
  }
}

export default {
  format,
  gateInTransactionFormat,
  findFieldValue,
  formatFormValues,
  formatUpdateValues
}
