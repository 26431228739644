import React from 'react'
import { useLocation } from 'react-router-dom'

import Stepper from './Stepper'
import BottomTabs from './BottomTabs'

const PATHS_WITH_BOTTOM_TABS = ['/transactions', '/visitors']
const PATHS_WITH_STEPPER = [
  '/transactions/create',
  '/visitors/create',
  '/transactions/update',
  '/visitors/update'
]

function Footer() {
  const { pathname } = useLocation()

  if (pathname.includes('succeed') || pathname.includes('error')) {
    return null
  }

  if (
    PATHS_WITH_STEPPER?.some(
      path =>
        pathname.startsWith(path) &&
        !(pathname.includes('door') || pathname.includes('succeed'))
    )
  ) {
    return <Stepper />
  }

  if (PATHS_WITH_BOTTOM_TABS?.includes(pathname)) {
    return <BottomTabs />
  }

  return null
}

export default Footer
